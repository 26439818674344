.AddCouponContainer {
  padding: 16px 0;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.StatusDisplayContainer {
  min-height: 600px;
  padding: 24px 12px;
}

.StatusDetailsContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.StatusDetailsLabel {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 2px solid black;
  margin-bottom: 12px;
}

.StatusDetailsContentContainer {
  display: flex;
  min-width: 100%;
}

.StatusDetailsContent {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.StatusColumn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /*  justify-content: center; */
}

.Label {
  p {
    font-weight: 700;
  }
}
.MuiSlider-markLabel {
  position: sticky;
}
.validationGrid {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  height: 100vh;
}

.validationChild1 {
  grid-area: 1 / 1 / 7 / 5;
  height: 100%;
  padding: 20px;
}
.validationChild2 {
  grid-area: 1 / 5 / 4 / 11;
  // max-height: 500px;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.validationChild3 {
  grid-area: 5 / 5 / 7 / 8;
  padding: 20px;
  display: flex;
  flex-direction: column;
  // max-height: 500px;
  overflow-y: scroll;
}
.validationChild4 {
  grid-area: 5 / 8 / 7 / 11;
  padding: 20px;
  display: flex;
  flex-direction: column;
  // max-height: 500px;
  overflow-y: scroll;
}
.messageBoard {
  margin-right: 20px;
  width: 100%;
  max-height: 260px;
  border: 0.5px solid grey;
  color: #2065d1;
  overflow: scroll;
}

.matchingParent {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(5, auto);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.matchingChild1 {
  grid-area: 1 / 1 / 2 / 2;
  max-height: 150px !important;
}
.matchingChild2 {
  grid-area: 1 / 2 / 2 / 3;
  max-height: 150px !important;
}
.matchingChild3 {
  grid-area: 1 / 3 / 2 / 4;
  max-height: 150px !important;
}
.matchingChild4 {
  grid-area: 1 / 4 / 2 / 5;
  max-height: 150px !important;
}
.matchingChild5 {
  grid-area: 1 / 5 / 2 / 6;
  max-height: 150px !important;
}
.matchingChild6 {
  grid-area: 1 / 6 / 2 / 7;
  max-height: 150px !important;
}
.matchingChild7 {
  grid-area: 2 / 1 / 6 / 7;
  display: table;
}

.rotatedtext {
  display: flex;
  justify-content: center;
  align-items: center;
  writing-mode: vertical-rl;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  width: 50px;
}

.emailParent {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.emailChild1 {
  grid-area: 1 / 1 / 6 / 3;
  max-height: 900px;
  overflow-y: scroll;
  margin-right: 1rem;
}
.emailChild2 {
  grid-area: 1 / 3 / 2 / 8;
  display: flex;
  justify-content: space-between;
}
.emailChild3 {
  grid-area: 2 / 3 / 6 / 8;
}

.flexEnd {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}

.contractGrid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.contractChild1 {
  grid-area: 1 / 1 / 6 / 3;
  border-right: 2px solid black;
  height: 100%;
  padding: 20px;
}
.contractChild2 {
  grid-area: 1 / 3 / 6 / 5;
  padding: 0 20px;
  border-right: 2px solid black;
}
.contractChild3 {
  grid-area: 1 / 5 / 6 / 7;
  padding: 0 20px;
}

.spanTitle {
  display: inline-block;
  font-size: 14px;
  width: 200px;
}
.width200 {
  width: 200px;
}
.width300 {
  width: 280px;
}

.marginTop16 {
  margin-top: 8px;
}

.interactionGrid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  // grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 0px;
}

.interactionChild1 {
  grid-area: 1 / 1 / 2 / 3;
  max-height: 400px;
}
.interactionChild2 {
  grid-area: 1 / 3 / 2 / 5;
  display: flex;
  flex-direction: column;
  max-height: 400px;
}
.interactionChild3 {
  grid-area: 1 / 5 / 2 / 7;
  display: flex;
  flex-direction: column;
  max-height: 400px;
}
.interactionChild4 {
  grid-area: 1 / 7 / 2 / 9;
  max-height: 400px;
}
.interactionChild5 {
  grid-area: 2 / 1 / 5 / 4;
}
.interactionChild6 {
  grid-area: 2 / 4 / 5 / 9;
  max-height: 600px;
  overflow-y: auto;
}

.remarksCreateIcon {
  cursor: pointer;
  color: gray;
}

.remarksCreateIcon:hover {
  color: #2065D1;
}
