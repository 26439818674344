.SwitchContainer {
  display: flex;
  align-items: center;
  width: 100%;
  p {
    flex: 60%;
    font-size: 14px;
    font-weight: 500;
    margin: 12px 0 12px 0;
  }
  span {
    font-size: 12px;
  }
}

.menuButton {
  background: none;
  border: none;
}
.is-active {
  background: #616161;
  color: white;
  border-radius: 2px;
}

.RangeInputFlexContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.RangeInputContainer {
  p {
    font-weight: 600;
    margin: 12px 0 0 0;
    font-size: 14px;
  }
}

.RangeInputField {
  width: 150px;
}

.RadioInputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  p {
    min-width: 300px;
  }
}

.editorContainer {
  border: 1px solid #616161;
  border-radius: 0.5rem;
  padding: 1rem;
}

.menuBar {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tiptap {
  border-top: 1px solid #616161;
  margin: 1rem 0;
  padding: 1rem;
  font-size: 1rem;
  min-height: 400px;

  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    border: 1px solid #616161;
    background: #0d0d0d;
    color: #fff;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#616161, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#616161, 0.1);
    margin: 2rem 0;
  }
}
