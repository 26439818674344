.FlexFullCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.css-mpr9kc-MuiPaper-root-MuiDrawer-paper,
.css-1y6sui9 {
  // width: 220px !important;
  width: 170px !important;
  box-shadow: none !important;
}

.css-1byr0tz {
  width: 100%;
}

.MuiBox-root .css-0 {
  width: 100%;
}

@media (min-width: 1200px) {
  .css-1qsxih2 {
    max-width: 100%;
  }
}

.css-1oqqzyl-MuiContainer-root {
  // max-width: 1260px;
  max-width: 100%;
  padding: 0;
}

.css-1869usk-MuiFormControl-root {
  min-width: 100px;
}

.MuiFormControl-root .css-b1884d {
  min-width: 100px;
}

.css-b1884d {
  min-width: 100px;
}

.MuiTableRow-root {
  cursor: pointer;
}

.Padding12 {
  padding: 12px;
}

.Width100 {
  width: 100%;
}

.LabelBoldM8 {
  font-weight: 700;
  margin: 8px 0;
}

.SpaceBetween {
  justify-content: space-between;
}

.SpaceAround {
  justify-content: space-around;
}

.FlexEnd {
  justify-content: flex-end;
}

.Flex {
  display: flex;
}