.ProviderProfileContainer {
  min-height: 600px;
  padding: 12px;
}

.TabContent {
  padding: 12px 0;
}

.TabContentFlexRow {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.ImageUploadContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ProfileImage {
  position: relative;
}

.UploadIcon {
  cursor: pointer;
  position: absolute;
  bottom: 6.5%;
  right: 5%;
}

.TextInput {
  width: 350px;
  margin: 12px 0;
}

.Width350 {
  width: 350px;
}
.Width550 {
  width: 550px;
}

.ReviewPaper {
  display: flex;
  margin: 12px 0;
  border-radius: 20px !important;
  /*   min-height: 150px !important; */
  padding: 16px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(129, 30, 30, 0.24);
}

.ReviewHeadingContainer {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.ReviewText {
  width: 100%;
}

.ButtonContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
  button {
    color: black;
  }
  button:hover {
    background-color: rgb(236, 236, 236);
  }
}

.InputPairs {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  
}