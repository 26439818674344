.ReferralDisplayContainer {
  min-height: 600px;
  padding: 24px 12px;
}

.ReferralDetailsContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.ReferralDetailsLabel {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 2px solid black;
  margin-bottom: 12px;
}

.ReferralDetailsContentContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ReferralDetailsContent {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.ReferralColumn {
  display: flex;
  align-items: center;
 /*  justify-content: center; */
  p {
    text-align: center;
  }
}

.Label {
  p {
    font-weight: 700;
  }
}
